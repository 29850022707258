import get from '@invitato/helpers/dist/getLocale';

export default {
  ayatName: get('Matius 19:6', 'Matthew 19:6'),
  ayatNameMuslim: get('Q.S. Ar-Rum: 21', 'Q.S. Ar-Rum: 21'),
  ayat: get(
    `Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.`,
    `“So they are no longer two, but one flesh. Therefore, what God has joined together, let no one separate.”`,
  ),
  ayatMuslim: get(
    `Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.`,
    `And of His signs is that He created for you from yourselves mates that you may find tranquility in them, and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.`,
  ),
  thanks: get('TERIMAKASIH UNTUK SEMUA KATA, <br />DOA, DAN PERHATIAN YANG DIBERIKAN.', 
  'THANK YOU FOR ALL THE WORDS, <br />PRAYERS, AND ATTENTION GIVEN'),
  closingAnnouncement: get('Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i tetap berkenan memberikan doa restu secara online untuk pelaksanaan hari pernikahan kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan.', 
  "It will be a joy for us if you are still willing to give your blessing from afar. Thank you for all the words, prayers, and attention given."),
  closingInvitation: get('Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dalam hari bahagia kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. Sampai jumpa di hari bahagia kami!',
  'It will be a joy for us if you are able to attend on our wedding day. Thank you for all the kind words, prayers, and attention given. See you on our wedding day!'),
};